<template>
  <div id="app" class="main">
    <header class="header">
      <Header />
    </header>
    <main>
        <ServiceCategories :categories="categories" @category-selected="handleCategorySelected" />
      <ServiceList v-if="selectedCategory" :services="selectedCategory.services" />
    </main>
    <footer class="footer">
      <Footer />
    </footer>
    <OrderModal v-if="isModalVisible" :service="selectedService" @close="isModalVisible = false"/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import ServiceCategories from './components/ServiceCategories.vue'
import ServiceList from './components/ServiceList.vue'
import servicesData from '@/data/services.json'
import OrderModal from './components/OrderModal.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    ServiceCategories,
    ServiceList,
    OrderModal
  },
  metaInfo: {
    title: 'Бухгалтер ФОП', // Тут можна використати ключові слова
    meta: [
      { name: 'description',
        content: 'Бухгалтер для ФОП, Бухгалтерські послуги ФОП, Ведення ФОП, Консультації ФОП, Відкриття ФОП'
      },
      { name: 'keywords',
        content: 'ліцензія алкоголь, акцизний податок, акциз, оператор потужності, фінмоніторинг, ведення фоп, відкрити рахунок, відкрити фоп, закрити фоп, фінмон, бухгалтер фоп, аутсорсинг бухгалтерії, бухгалтерські послуги, квартальний звіт фоп'
      }
    ]
  },
  data() {
    return {
      categories: servicesData
          .categories,
      selectedCategory: null,
      isModalVisible: false
    }
  },
  methods: {
    handleCategorySelected(category) {
      this.selectedCategory = category;
    }
  }
}
</script>

<style lang="scss">
@import './assets/style.scss';
</style>

