<template>
  <div>
    <h2 class="text-header3"> >>>>  Оберіть категорію послуг нижче для перегляду вартості</h2>
    <button v-for="category in categories" :key="category.name" @click="selectCategory(category)" class="categories">
      {{ category.name }}
    </button>
    <div id="category-list">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceCategories',
  props: ['categories'],
  methods: {
    selectCategory(category) {
      this.$emit('category-selected', category);
      this.scrollToCategoryList();
    },
    scrollToCategoryList() {
      const categoryList = document.getElementById('category-list');
      if (categoryList) {
        categoryList.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    metaInfo: {
      title: 'Бухгалтер ФОП', // Тут можна використати ключові слова
      meta: [
        { name: 'description',
          content: 'Бухгалтер для ФОП, Бухгалтерські послуги ФОП, Ведення ФОП, Консультації ФОП, Відкриття ФОП'
        },
        { name: 'keywords',
          content: 'ліцензія алкоголь, акцизний податок, акциз, оператор потужності, фінмоніторинг, ведення фоп, відкрити рахунок, відкрити фоп, закрити фоп, фінмон, бухгалтер фоп, аутсорсинг бухгалтерії, бухгалтерські послуги, квартальний звіт фоп'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '../assets/style.scss';
</style>
