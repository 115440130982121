import axios from 'axios';

const API_URL = 'https://api.telegram.org/bot';
const TOKEN = process.env.VUE_APP_TELEGRAM_BOT_TOKEN;
const CHAT_ID = process.env.VUE_APP_TELEGRAM_CHAT_ID;

export const sendOrderMessage = async (orderDetails) => {
    const messageText = `Нове замовлення: ${orderDetails.service} \nЦіна: ${orderDetails.price} \nІм'я: ${orderDetails.name} \nТелефон: ${orderDetails.phone} \nДата: ${orderDetails.date} \nЧас: ${orderDetails.time}`;

    try {
        const url = `${API_URL}${TOKEN}/sendMessage`;
        const response = await axios.post(url, {
            chat_id: CHAT_ID,
            text: messageText,
        });
        return response.data;
    } catch (error) {
        console.error('Error sending order message:', error);
        return null;
    }
};
