<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <button class="close-btn" @click="$emit('close')">×</button>
      <h2>Замовлення послуги</h2>
      <input v-model="name" placeholder="Ім'я">
      <input v-model="phone" placeholder="Номер телефону">
      <h3 class="text-h3">Оберіть бажану дату та час надання послуги</h3>
      <input v-model="date" type="date" placeholder="Дата">
      <input v-model="time" type="time" placeholder="Час">
      <button @click="submitOrder" class="submit-btn">Підтвердити</button>
    </div>
  </div>
</template>

<script>

import { sendOrderMessage } from '@/data/telegramService';

export default {
  name: 'OrderModal',
  props: ['service', 'price'],
  data() {
    return {
      name: '',
      phone: '',
      date: '', // Ініціалізація поля для дати
      time: ''  // Ініціалізація поля для часу
    };
  },
  mounted() {
    this.toggleBlur(true);

    const now = new Date();

    // Якщо поточний час після 17:30
    if (now.getHours() > 17 || (now.getHours() === 17 && now.getMinutes() > 30)) {
      // Додати один день до поточної дати
      now.setDate(now.getDate() + 1);
      this.date = this.formatDate(now);
      this.time = "10:00"; // Встановити час на 10:00
    }
    else if (now.getHours() < 10) {
      this.date = this.formatDate(now);
      this.time = "10:00"; // Встановити час на 10:00
    } else {
      // Встановити поточну дату
      this.date = this.formatDate(now);

      // Визначення часу на наступну годину або наступні 30 хвилин
      let hours = now.getHours();
      let minutes = now.getMinutes() < 30 ? "30" : "00";
      if (minutes === "00") hours += 1; // Перехід на наступну годину, якщо хвилини були >= 30

      // Форматування години для відповідності формату "HH:MM"
      hours = hours < 10 ? `0${hours}` : hours;
      this.time = `${hours}:${minutes}`;
    }
  },
  beforeUnmount() {
    this.toggleBlur(false);
  },
  methods: {
    toggleBlur(enable) {
      const content = document.querySelector('.main-content');
      if (content) {
        if (enable) {
          content.classList.add('blur-effect');
        } else {
          content.classList.remove('blur-effect');
        }
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Місяці в JavaScript починаються з 0
      const day = date.getDate();

      // Форматування місяця і дня для відповідності формату "YYYY-MM-DD"
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      return `${year}-${formattedMonth}-${formattedDay}`;
    },
    submitOrder() {
      const orderDetails = {
        service: this.service, // Використовуйте ім'я послуги з props
        price: this.price, // Ціна послуги теж може бути корисною
        name: this.name,
        phone: this.phone,
        date: this.date,
        time: this.time
      };
      // Використання axios для відправлення запиту
      sendOrderMessage(orderDetails)
          .then(() => {
            alert('✅ Замовлення оформлено успішно! \n Консультант з вами зв\'яжеться в зазначений час!');
            this.$emit('close');
          })
          .catch(error => {
            console.error('Помилка при відправленні замовлення:', error);
          });
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/popup.scss';
</style>
