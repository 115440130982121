<template>
  <footer class="footer">
    <div class="contact-info">
      <p>Телефон: +38(099)0918131</p>
      <p>Email: buh911.com.ua@gmail.com</p>
      <p>Адреса: м.Київ,вул.Богдана Гаврилишина,13/1</p>
    </div>
    <div class="social-links">
      <a href="https://t.me/buh911" target="_blank" class="social-icon">
        <img src="../assets/images/icons/telegram.png" alt="Telegram">
      </a>
      <a href="viber://chat?number=+380990918131" class="social-icon">
        <img src="../assets/images/icons/viber.png" alt="Viber">
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
}
</script>

<style lang="scss">
@import '../assets/footer.scss';
</style>
