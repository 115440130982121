<template>
  <div class="services">
    <div v-for="service in services" :key="service.name" class="service-frame">
      <h3 class="service-header">{{ service.name }}</h3>
      <p class="service-descr">{{ service.description }}</p>
      <button @click="orderService(service.name, service.price)" class="order-button">
        <p>Замовити за: {{ service.price }} грн</p>
      </button>
    </div>
    <OrderModal v-if="showOrderModal" :service="selectedService" :price="selectedPrice" @close="showOrderModal = false" />
  </div>
</template>

<script>
import OrderModal from './OrderModal.vue'
export default {
  name: 'ServiceList',
  components: {
    OrderModal
  },
  props: ['services'],
  data() {
    return {
      showOrderModal: false,
      selectedService: null,
      selectedPrice: null
    }
  },
  methods: {
    orderService(serviceName, price) {
      //console.log('Ordering service:', serviceName, 'with price:', price);
      this.selectedService = serviceName;
      this.selectedPrice = price;
      this.showOrderModal = true;
    }
  }
}
</script>

<style lang="scss">
@import '../assets/style.scss';
</style>
