<template>
  <div>
    <div class="logo">
      <a href="http://buh911.com.ua">
        <img src="@/assets/images/logo.png" alt="Logo">
      </a>
    </div>
    <div>
      <h1 class="text-header">Бухгалтерські послуги для ФОП</h1>
<!--      <p class="warning-blink">Попередження! Хороший бухгалтер - це не дешево! За нас говорять відгуки наших замовників.</p>-->
    </div>
  </div>
    <!-- Інші частини вашого компонента -->
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  metaInfo: {
    title: 'Бухгалтер ФОП', // Тут можна використати ключові слова
    meta: [
      { name: 'description',
        content: 'Бухгалтер для ФОП, Бухгалтерські послуги ФОП, Ведення ФОП, Консультації ФОП, Відкриття ФОП'
      },
      { name: 'keywords',
        content: 'ліцензія алкоголь, акцизний податок, акциз, оператор потужності, фінмоніторинг, ведення фоп, відкрити рахунок, відкрити фоп, закрити фоп, фінмон, бухгалтер фоп, аутсорсинг бухгалтерії, бухгалтерські послуги, квартальний звіт фоп'
      }
    ]
  }
}
</script>

<style lang="scss">
@import '../assets/style.scss';
</style>
